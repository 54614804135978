<template>
  <div style="background: #fff; max-width: 800px" class="expande-horizontal">
    <div ref="container" class="three-container"></div>
    <div
      ref="tooltip"
      class="tooltip"
      v-show="tooltip.visible"
      :style="{ top: tooltip.y + 'px', left: tooltip.x + 'px' }"
    >
      {{ tooltip.text }}
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";

export default {
  name: "TwoSpheresWithLine",
  data() {
    return {
      // scene: null,
      // camera: null,
      // renderer: null,
      // spheres: [],
      // line: null,
      scene: null,
      camera: null,
      renderer: null,
      raycaster: new THREE.Raycaster(),
      mouse: new THREE.Vector2(),
      spheres: [],
      line: null,
      tooltip: {
        visible: false,
        text: "",
        x: 0,
        y: 0
      },
      font: null
    };
  },
  mounted() {
    this.initThree();
    this.loadFontAndCreateSpheres();
    this.createSpheres();
    this.createLine();
    this.animate();
    window.addEventListener("mousemove", this.onMouseMove);
  },
  methods: {
    initThree() {
      // Configuração básica da cena, câmera e renderizador
      this.scene = new THREE.Scene();
      this.camera = new THREE.PerspectiveCamera(
        75,
        this.$refs.container.clientWidth / this.$refs.container.clientHeight,
        0.1,
        1000
      );
      this.renderer = new THREE.WebGLRenderer();
      this.renderer.setSize(
        this.$refs.container.clientWidth,
        this.$refs.container.clientHeight
      );
      this.$refs.container.appendChild(this.renderer.domElement);

      // Configuração inicial da câmera
      this.camera.position.z = 10;

      // Adicionar luz
      const light = new THREE.AmbientLight(0x404040);
      this.scene.add(light);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
      this.scene.add(directionalLight);
    },
    createSpheres() {
      const textrueLoader = new THREE.TextureLoader();
      // this.camera.lookAt(new THREE.Vector3(1, 0, 5));

      // let texture1 = textrueLoader.load(
      //   'https://static.vecteezy.com/system/resources/thumbnails/027/951/137/small_2x/stylish-spectacles-guy-3d-avatar-character-illustrations-png.png'
      // );

      const geometry = new THREE.SphereGeometry(1, 20, 20);
      const material = new THREE.MeshBasicMaterial({ color: 0xffffff });
      //https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png

      // Criar duas esferas
      const sphere1 = new THREE.Mesh(geometry, material);
      sphere1.position.set(-3, 0, 0);
      // sphere1.rotation.y = 5;
      sphere1.userData.tooltipText = "tooltipText 1";
      if (this.font) {
        const textGeometry = new TextGeometry("Patrick", {
          font: this.font,
          size: 0.5,
          height: 0.1
        });
        const textMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
        const textMesh = new THREE.Mesh(textGeometry, textMaterial);
        textMesh.position.set(0, 0, 0);
        this.scene.add(textMesh);
      }
      this.scene.add(sphere1);
      this.spheres.push(sphere1);

      const sphere2 = new THREE.Mesh(geometry, material);
      sphere2.position.set(3, 0, 0);
      // sphere2.rotation.y = 5;
      sphere2.userData.tooltipText = "tooltipText 2";
      if (this.font) {
        const textGeometry2 = new TextGeometry("Patrick 2", {
          font: this.font,
          size: 0.5,
          height: 0.1
        });
        const textMaterial2 = new THREE.MeshBasicMaterial({ color: 0xffffff });
        const textMesh2 = new THREE.Mesh(textGeometry2, textMaterial2);
        textMesh2.position.set(0, 0, 0);
        this.scene.add(textMesh2);
      }
      this.scene.add(sphere2);
      this.spheres.push(sphere2);
    },
    loadFontAndCreateSpheres() {
      const fontLoader = new FontLoader();
      fontLoader.load(
        "https://threejs.org/examples/fonts/helvetiker_regular.typeface.json",
        font => {
          this.font = font;
          // this.createSpheresWithTextures();
        }
      );
    },
    createLine() {
      const material = new THREE.LineBasicMaterial({ color: 0x0000ff });
      const points = [
        new THREE.Vector3(
          this.spheres[0].position.x,
          this.spheres[0].position.y,
          this.spheres[0].position.z
        ),
        new THREE.Vector3(
          this.spheres[1].position.x,
          this.spheres[1].position.y,
          this.spheres[1].position.z
        )
      ];
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      this.line = new THREE.Line(geometry, material);
      this.scene.add(this.line);
    },
    animate() {
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    },
    onMouseMove(event) {
      const rect = this.$refs.container.getBoundingClientRect();
      this.mouse.x =
        ((event.clientX - rect.left) / this.$refs.container.clientWidth) * 2 -
        1;
      this.mouse.y =
        -((event.clientY - rect.top) / this.$refs.container.clientHeight) * 2 +
        1;

      // this.mouse.x = event.clientX;
      // this.mouse.x = event.clientX;

      this.raycaster.setFromCamera(this.mouse, this.camera);
      const intersects = this.raycaster.intersectObjects(this.spheres);

      if (intersects.length > 0) {
        console.log("asd", event.clientX, event.clienteY);
        console.log("in", intersects);
        const intersected = intersects[0].object;
        this.tooltip.visible = true;
        this.tooltip.text = intersected.userData.tooltipText;
        this.tooltip.x = event.clientX + 10;
        this.tooltip.y = event.clientY + 10;
      } else {
        this.tooltip.visible = false;
      }
    }
  },
  beforeDestroy() {
    this.$refs.container.removeChild(this.renderer.domElement);
  }
};
</script>

<style scoped>
.three-container {
  width: 100%;
  height: 100vh;
}
.tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px;
  border-radius: 3px;
  pointer-events: none;
  z-index: 10;
}
</style>
