<template>
  <layout :title="$route.name">
    <div
      class="expande-horizontal column centraliza"
      style="max-width: 100%; overflow: auto; min-height: 96vh; justify-content: center; padding-left: 20px;"
    >
      <!-- {{ binary }} -->
      <!-- <BThree /> -->
      <v-dialog width="350" v-model="modalViewShortBinary">
        <v-card width="350">
          <div class="expande-horizontal column">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="fonte-bold">
                      {{ modalBinaryContent.indicated_name }}
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span class="fonte-bold grey--text">Item da rede</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </v-list>
            <div class="expande-horizontal"></div>
          </div>
        </v-card>
      </v-dialog>
      <div class="expande-horizontal centraliza">
        <div class="expande-horizontal centraliza">
          <div style="display: flex; align-items: center;">
            <v-card
              width="100"
              color="#333"
              dark
              class="elevation-0"
              style="border: none; border-radius: 12px; border-top-right-radius: 0px; border-bottom-right-radius: 0px; margin-right: 3px; z-index: 1"
              height="55"
            >
              <div
                class="expande-horizontal column pa-2"
                style="align-items: flex-end;"
              >
                <span style="font-size: 14pt;" class="fonte-bold">
                  0
                </span>
                <span class="fonte-bold font-weight-light">
                  Esquerdo
                </span>
              </div>
            </v-card>
            <div class="level">
              <div
                style="width: 140px; height: 140px;"
                color="orange"
                class="octagon"
                dark
              >
                <div class="expande-horizontal centraliza column">
                  <v-icon>mdi-account-circle</v-icon>
                  <span class="fonte-bold black--text"> Você</span>
                </div>
              </div>
              <div class="trace"></div>
            </div>
            <v-card
              width="100"
              color="#333"
              dark
              class="elevation-0"
              style="border: none; border-radius: 12px; border-top-left-radius: 0px; border-bottom-left-radius: 0px; margin-left: 3px; z-index: 1"
              height="55"
            >
              <div class="expande-horizontal column pa-2">
                <span style="font-size: 14pt;" class="fonte-bold">
                  0
                </span>
                <span class="fonte-bold">
                  Direito
                </span>
              </div>
            </v-card>
          </div>
        </div>
      </div>
      <!-- level 1 -->
      <div class="expande-horizontal centraliza">
        <div
          :class="{
            'margin-left-1': i.side == 'right',
            'margin-right-1': i.side == 'left'
          }"
          class="level"
          @click="openItemBinary(i)"
          v-for="(i, index1) in processeds.level_1"
          :key="`level_1_${index1}`"
        >
          <div size="80" class="octagon" color="orange" dark>
            <div class="expande-horizontal centraliza column">
              <v-icon>mdi-account-circle-outline</v-icon>
              <span
                style="font-size: 14pt;"
                class="fonte-bold fonteMini black--text"
                >{{ i.indicated_name }}</span
              >
            </div>
          </div>
          <div class="trace-1"></div>
          <div
            class="trace-right-1"
            v-if="
              processeds.level_1.length > 1 &&
                index1 + 1 < processeds.level_1.length
            "
          ></div>
          <div class="trace-left-1" v-if="index1 > 0"></div>
        </div>
      </div>
      <!-- n2 -->
      <div class="expande-horizontal centraliza mt-0">
        <div
          :class="{
            'margin-left-2': i.side == 'right',
            'margin-right-2': i.side == 'left'
          }"
          class="level"
          @click="openItemBinary(i)"
          v-for="(i, index2) in processeds.level_2"
          :key="`level_1_${index2}`"
        >
          <div style="z-index: 99" size="70" class="octagon" dark>
            <div class="expande-horizontal centraliza column">
              <v-icon>mdi-account-circle-outline</v-icon>
              <span
                style="font-size: 14pt;"
                class="fonte-bold fonteMini black--text"
                >{{ i.indicated_name }}</span
              >
            </div>
          </div>
          <div class="trace-2"></div>
          <div class="trace-right-2" v-if="i.side == 'left'"></div>
          <div class="trace-left-2" v-if="i.side == 'right'"></div>
        </div>
      </div>
      <!-- level 3 -->
      <div class="expande-horizontal centraliza mt-2">
        <div
          :class="{
            'margin-left-3': i.side == 'right',
            'margin-right-3': i.side == 'left'
          }"
          class="level"
          @click="openItemBinary(i)"
          v-for="(i, index3) in processeds.level_3"
          :key="`level_3_${index3}`"
        >
          <div style="width: 60px; height: 60px;" class="octagon" dark>
            <div class="expande-horizontal centraliza column">
              <v-icon>mdi-account-circle-outline</v-icon>
              <span
                style="font-size: 14pt;"
                class="fonte-bold fonteMini black--text"
                >{{ i.indicated_name }}</span
              >
            </div>
          </div>
          <div class="trace-3"></div>
          <div class="trace-right-3" v-if="i.side == 'left'"></div>
          <div class="trace-left-3" v-if="i.side == 'right'"></div>
        </div>
      </div>
      <!-- level 4 -->
      <div class="expande-horizontal centraliza mt-2">
        <div
          :class="{
            'margin-left-4': i.side == 'right',
            'margin-right-4': i.side == 'left'
          }"
          class="level"
          @click="openItemBinary(i)"
          v-for="(i, index4) in processeds.level_4"
          :key="`level_4_${index4}`"
        >
          <div style="width: 30px; height: 30px;" class="octagon" dark>
            <div class="expande-horizontal centraliza column">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="black" dark v-bind="attrs" v-on="on" icon>
                    <v-icon>mdi-account-circle-outline</v-icon>
                    <!-- 😁 -->
                  </v-btn>
                </template>
                <span>{{ i.indicated_name }}</span>
              </v-tooltip>
            </div>
          </div>
          <div class="trace-4"></div>
          <div class="trace-right-4" v-if="i.side == 'left'"></div>
          <div class="trace-left-4" v-if="i.side == 'right'"></div>
        </div>
      </div>
      <!-- level 5 -->
      <div class="expande-horizontal centraliza mt-2">
        <div
          :class="{
            'margin-left-5': i.side == 'right',
            'margin-right-5': i.side == 'left'
          }"
          class="level"
          @click="openItemBinary(i)"
          v-for="(i, index5) in processeds.level_5"
          :key="`level_5_${index5}`"
        >
          <div style="width: 20px; height: 20px;" class="octagon" dark>
            <div class="expande-horizontal centraliza column">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="black" dark v-bind="attrs" v-on="on" icon>
                    <v-icon size="19">mdi-account-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ i.indicated_name }}</span>
              </v-tooltip>
            </div>
          </div>
          <div class="trace-5"></div>
          <div class="trace-right-5" v-if="i.side == 'left'"></div>
          <div class="trace-left-5" v-if="i.side == 'right'"></div>
        </div>
      </div>
      <!-- nivel 6 -->
      <div class="expande-horizontal centraliza mt-2">
        <div
          :class="{
            'margin-left-6': i.side == 'right',
            'margin-right-6': i.side == 'left'
          }"
          class="level"
          @click="openItemBinary(i)"
          v-for="(i, index6) in processeds.level_6"
          :key="`level_6_${index6}`"
        >
          <div style="width: 15px; height: 15px;" class="octagon" dark>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="black" dark v-bind="attrs" v-on="on" icon>
                  <!-- <v-icon>mdi-account-circle-outline</v-icon> -->
                </v-btn>
              </template>
              <span>{{ i.indicated_name }}</span>
            </v-tooltip>
          </div>
          <div class="trace-6"></div>
          <div class="trace-right-6" v-if="i.side == 'left'"></div>
          <div class="trace-left-6" v-if="i.side == 'right'"></div>
        </div>
      </div>
      <!-- nivel 7 -->
      <div class="expande-horizontal centraliza mt-2">
        <div
          :class="{
            'margin-left-7': i.side == 'right',
            'margin-right-7': i.side == 'left'
          }"
          class="level"
          @click="openItemBinary(i)"
          v-for="(i, index7) in processeds.level_7"
          :key="`level_7_${index7}`"
        >
          <div style="width: 10px; height: 10px;" class="octagon" dark>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="black" dark v-bind="attrs" v-on="on" icon>
                  <!-- <v-icon>mdi-account-circle-outline</v-icon> -->
                </v-btn>
              </template>
              <span>{{ i.indicated_name }}</span>
            </v-tooltip>
          </div>
          <div class="trace-7"></div>
          <div class="trace-right-7" v-if="i.side == 'left'"></div>
          <div class="trace-left-7" v-if="i.side == 'right'"></div>
        </div>
      </div>
      <!-- nivel 8 -->
      <div class="expande-horizontal centraliza mt-1">
        <div
          :class="{
            'margin-left-8': i.side == 'right',
            'margin-right-8': i.side == 'left'
          }"
          class="level"
          @click="openItemBinary(i)"
          v-for="(i, index8) in processeds.level_8"
          :key="`level_8_${index8}`"
        >
          <div style="width: 5px; height: 5px;" class="octagon" dark>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="black" dark v-bind="attrs" v-on="on" icon>
                  <!-- <v-icon>mdi-account-circle-outline</v-icon> -->
                </v-btn>
              </template>
              <span>{{ i.indicated_name }}</span>
            </v-tooltip>
          </div>
          <div class="trace-8"></div>
          <div class="trace-right-4" v-if="i.side == 'left'"></div>
          <div class="trace-left-4" v-if="i.side == 'right'"></div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import { mapGetters } from "vuex";
import BThree from "./components/BinaryTree.vue";

export default {
  components: {
    BThree
  },
  data() {
    return {
      modalViewShortBinary: false,
      modalBinaryContent: {},
      binary: {
        level_1: [],
        level_2: [],
        level_3: [],
        level_4: [],
        level_5: [],
        level_6: [],
        level_7: [],
        level_8: [],
        level_9: [],
        level_10: []
      },
      binary_bkp: {
        level_1: [],
        level_2: [],
        level_3: [],
        level_4: [],
        level_5: [],
        level_6: [],
        level_7: [],
        level_8: [],
        level_9: [],
        level_10: []
      },
      processeds: {
        level_1: [],
        level_2: [],
        level_3: [],
        level_4: [],
        level_5: [],
        level_6: [],
        level_7: [],
        level_8: [],
        level_9: [],
        level_10: []
      },
      loading: false
    };
  },
  methods: {
    openItemBinary(item) {
      this.modalViewShortBinary = true;
      this.modalBinaryContent = item;
    },
    listBinaries() {
      this.$run("binary/list", null, "user").then(res => {
        this.binary_bkp = res.data;
        let processeds = {
          level_1: [],
          level_2: [],
          level_3: [],
          level_4: [],
          level_5: [],
          level_6: [],
          level_7: [],
          level_8: [],
          level_9: [],
          level_10: []
        };
        let self = this;

        for (let i = 0; i <= 10; i++) {
          // console.log("i",i);
          if (i == 0) {
            let left = self.binary_bkp[`level_${i + 1}`].filter(
              child => child.side == "left"
            );
            let right = self.binary_bkp[`level_${i + 1}`].filter(
              child => child.side == "right"
            );
            console.log("left", left, "right", right);
            processeds[`level_${i + 1}`] = [
              left.length === 1
                ? left[0]
                : {
                    sponsor_id: self.getLoggedUser._id,
                    indicated_name: "vazio",
                    empty: true,
                    side: "left",
                    level: 1
                  },
              right.length === 1
                ? right[0]
                : {
                    sponsor_id: self.getLoggedUser._id,
                    indicated_name: "vazio",
                    empty: true,
                    side: "right",
                    level: 1
                  }
            ];
            self.processeds = processeds;
          } else if (i >= 1) {
            processeds[`level_${i}`].filter(sponsor => {
              let left = self.binary_bkp[`level_${i + 1}`].filter(
                child =>
                  child.side == "left" &&
                  child.level == i &&
                  child.sponsor_id == sponsor._id
              );
              let right = self.binary_bkp[`level_${i + 1}`].filter(
                child =>
                  child.side == "right" &&
                  child.level == i &&
                  child.sponsor_id == sponsor._id
              );

              processeds[`level_${i + 1}`] = [
                ...processeds[`level_${i + 1}`],
                left.length === 1
                  ? left[0]
                  : {
                      sponsor_id: sponsor._id,
                      indicated_name: "vazio",
                      empty: true,
                      side: "left",
                      level: i + 1
                    },
                right.length === 1
                  ? right[0]
                  : {
                      sponsor_id: sponsor._id,
                      indicated_name: "vazio",
                      empty: true,
                      side: "right",
                      level: i + 1
                    }
              ];
            });
            self.processeds = processeds;
            return processeds;
          }
        }
        this.loading = true;
      });
    }
  },
  computed: {
    ...mapGetters(["getLoggedUser"])
  },
  created() {
    this.listBinaries();
  }
};
</script>

<style scoped>
.level {
  position: relative;
  cursor: pointer;
  /* display: flex; */
  /* justify-content: center; */
}
.trace {
  position: absolute;
  height: 40px;
  width: 1px;
  top: 140px;
  left: 70px;
  background: #868484;
}
.trace-1 {
  position: absolute;
  height: 40px;
  width: 1px;
  top: 80px;
  left: 40px;
  background: #868484;
}
.trace-2 {
  position: absolute;
  height: 49px;
  width: 1px;
  top: 80px;
  left: 40px;
  background: #868484;
}
.trace-3 {
  position: absolute;
  height: 24px;
  width: 1px;
  top: 60px;
  left: 30px;
  background: #868484;
}
.trace-4 {
  position: absolute;
  height: 18px;
  width: 1px;
  top: 30px;
  left: 15px;
  background: #868484;
}
.trace-5 {
  position: absolute;
  height: 17px;
  width: 1px;
  top: 20px;
  left: 10px;
  background: #868484;
}
.trace-6 {
  position: absolute;
  height: 17px;
  width: 1px;
  top: 12px;
  left: 7px;
  background: #868484;
}
.trace-7 {
  position: absolute;
  height: 10px;
  width: 1px;
  top: 8px;
  left: 5px;
  background: #868484;
}
.trace-right-1 {
  position: absolute;
  height: 1px;
  width: 380px;
  left: 80px;
  top: 40px;
  background: #868484;
}
.trace-left-1 {
  position: absolute;
  height: 1px;
  width: 380px;
  left: -380px;
  top: 40px;
  background: #868484;
}
/* left == tamanho do v-avatar */
.trace-right-2 {
  position: absolute;
  height: 1px;
  width: 140px;
  left: 80px;
  top: 40px;
  background: #868484;
}
/* left == tamanho do v-avatar */
.trace-left-2 {
  position: absolute;
  height: 1px;
  width: 160px;
  left: -160px;
  top: 40px;
  background: #868484;
  z-index: 1;
}
/* left == tamanho do v-avatar */
.trace-right-3 {
  position: absolute;
  height: 1px;
  width: 70px;
  left: 60px;
  top: 40px;
  background: #868484;
}
/* left == tamanho do v-avatar */
.trace-left-3 {
  position: absolute;
  height: 1px;
  width: 70px;
  left: -70px;
  top: 40px;
  background: #868484;
  z-index: 1;
}
.trace-right-4 {
  position: absolute;
  height: 1px;
  width: 40px;
  left: 30px;
  top: 15px;
  background: #868484;
}
/* left == tamanho do v-avatar */
.trace-left-4 {
  position: absolute;
  height: 1px;
  width: 40px;
  left: -40px;
  top: 15px;
  background: #868484;
  z-index: 1;
}
.trace-right-5 {
  position: absolute;
  height: 1px;
  width: 17px;
  left: 35px;
  top: 10px;
  background: #868484;
}
/* left == tamanho do v-avatar */
.trace-left-5 {
  position: absolute;
  height: 1px;
  width: 17px;
  left: -32px;
  top: 10px;
  background: #868484;
  z-index: 1;
}
.margin-left-1 {
  margin-left: 380px;
}
.margin-right-1 {
  margin-right: 380px;
}
.margin-left-2 {
  margin-left: 150px;
  margin-right: 180px;
}
.margin-right-2 {
  margin-right: 150px;
  margin-left: 180px;
}
.margin-left-3 {
  margin-left: 70px;
  margin-right: 85px;
}
.margin-right-3 {
  margin-right: 70px;
  margin-left: 85px;
}
.margin-left-4 {
  margin-left: 38px;
  margin-right: 38px;
}
.margin-right-4 {
  margin-right: 38px;
  margin-left: 38px;
}
.margin-left-5 {
  margin-left: 16px;
  margin-right: 17px;
}
.margin-right-5 {
  margin-right: 16px;
  margin-left: 17px;
}
.margin-left-6 {
  margin-left: 3px;
  margin-right: 8.5px;
}
.margin-right-6 {
  margin-right: 3px;
  margin-left: 8.5px;
}
.margin-left-7 {
  margin-left: 0px;
  margin-right: 3.2px;
}
.margin-right-7 {
  margin-right: 0px;
  margin-left: 3.2px;
}
.margin-left-8 {
  margin-left: 0px;
  margin-right: 1.6px;
}
.margin-right-8 {
  margin-right: 0px;
  margin-left: 1.6px;
}
.octagon {
  /* width: 200px;
    height: 200px; */
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 999;
  background: #f2994a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f2c94c,
    #f2994a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f2c94c,
    #f2994a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  box-shadow: 10px 10px 20px 20px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
  clip-path: polygon(
    30% 0%,
    /* top-left */ 70% 0%,
    /* top-right */ 100% 30%,
    /* right-top */ 100% 70%,
    /* right-bottom */ 70% 100%,
    /* bottom-right */ 30% 100%,
    /* bottom-left */ 0% 70%,
    /* left-bottom */ 0% 30% /* left-top */
  ) !important;
}
.octagon:hover {
  transform: scale(1.3);
  transition: 0.4s;
  z-index: 999;
  clip-path: circle(50%) !important;
}
</style>
